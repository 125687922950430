// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable radix */

import moment from 'moment';

export const PMT = (rate, nper, pv) => {
	const pvif = (1 + rate) ** nper;
	const pmt = (rate / (pvif - 1)) * -(pv * pvif);
	return Math.round(pmt).toFixed(2);
};

export const calculateSchedule = (loanRequestDetails) => {
	let dueCounts = 12;
	if (loanRequestDetails.typeCollection === 'ForthNight') {
		dueCounts = 15;
	} else if (loanRequestDetails.typeCollection === 'Weekly') {
		dueCounts = 7;
	} else if (loanRequestDetails.typeCollection === 'Daily') {
		dueCounts = 1;
	}
	const pincipalAmt = loanRequestDetails.limit * (loanRequestDetails.percentage / 100);
	const calPrincipalAmt =
		parseInt(loanRequestDetails.limit) / parseInt(loanRequestDetails.tenure);
	const calInterestAmt = parseInt(pincipalAmt) / parseInt(loanRequestDetails.tenure);
	let calEMIAmt = parseInt(calPrincipalAmt) + parseInt(calInterestAmt);
	calEMIAmt = calEMIAmt.toFixed(2);
	console.log('calEMIAmt', calEMIAmt);
	// const interestAmtVal = parseInt((limit * percentage) / 100);
	const payment = PMT(
		loanRequestDetails.percentage / 100 / dueCounts,
		loanRequestDetails.tenure,
		-loanRequestDetails.limit,
	);
	let loanScheduleArray = '';
	// const tenureCount = [loanRequestDetails.tenure];
	const tenureCount = [];
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < loanRequestDetails.tenure; i++) {
		const data = {
			Tenure: i,
		};
		tenureCount.push(data);
	}

	if (loanRequestDetails.collectionPayDay === '1') {
		loanScheduleArray = computeSchedule2(
			loanRequestDetails.limit,
			loanRequestDetails.percentage,
			loanRequestDetails.tenure,
			tenureCount,
			loanRequestDetails.typeCollection,
		);
	} else if (loanRequestDetails.loanType === 'Custom') {
		loanScheduleArray = computeCustomSchedule(
			loanRequestDetails.limit,
			loanRequestDetails.percentage,
			loanRequestDetails.tenure,
			payment,
			dueCounts,
			tenureCount,
			loanRequestDetails.typeCollection,
		);
	} else {
		loanScheduleArray = computeSchedule(
			loanRequestDetails.limit,
			loanRequestDetails.percentage,
			loanRequestDetails.tenure,
			payment,
			dueCounts,
			tenureCount,
			loanRequestDetails.typeCollection,
		);
	}
	const loanSchedule = loanScheduleArray;

	return loanSchedule;
};

export const computeSchedule = (
	principal,
	percentage,
	tenure,
	payment,
	tenureMonth,
	maxTenure,
	installmentType,
) => {
	let remaining = principal;
	let nextDate;
	const loanArrData = [];
	// eslint-disable-next-line array-callback-return
	maxTenure.map((item, index) => {
		if (item.Tenure <= tenure) {
			let interest = Math.round(remaining) * (percentage / 100 / tenureMonth);
			interest = interest.toFixed(2);
			let principle = payment - interest;
			principle = Math.round(principle);
			interest = Math.round(interest);
			let EMI = parseFloat(principle) + parseFloat(interest);
			remaining -= principle;

			let futureDate;
			let addDays = 'M';
			if (installmentType === 'Daily' || installmentType === 'ForthNight') {
				addDays = 'd';
			} else if (installmentType === 'Weekly') {
				addDays = 'w';
			}
			if (index === 0) {
				const currentDate = moment().format('YYYY/MM/DD');
				futureDate = moment(currentDate).add(1, addDays).format('YYYY-MM-DD');
				if (installmentType === 'ForthNight') {
					futureDate = moment(currentDate).add(14, addDays);
				}
			} else {
				console.log('installmentType', installmentType);
				futureDate = moment(nextDate).add(1, addDays);
				if (installmentType === 'ForthNight') {
					futureDate = moment(nextDate).add(14, addDays);
				}
			}
			// const futureMonthEnd = moment(futureDate).endOf('month');

			// if (futureDate.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
			//   futureDate = futureDate.add(1, 'd');
			// }
			nextDate = futureDate;
			if (tenure === item.Tenure) {
				principle += remaining;
				EMI += remaining;
				remaining = 0;
			}

			const loanData = {
				index: item.Tenure + 1,
				loanPrinciple: Math.round(principle),
				loanInterest: Math.round(interest),
				loanEMI: Math.round(EMI),
				loanRemaining: Math.round(remaining),
				loanDueDate: moment(futureDate).format('YYYY-MM-DD'),
				loanDueDateUI: `${moment(futureDate).format('DD')} ${moment(futureDate)
					.format('MMMM')
					.substring(0, 3)} ${moment(futureDate).format('MM')}`,
			};
			loanArrData.push(loanData);
			remaining = remaining.toFixed(2);
		}
	});
	return loanArrData;
};

export const computeSchedule2 = (principal, percentage, tenure, maxTenure, installmentType) => {
	const checkPrincipal = principal;
	let remaining = principal;
	let nextDate;
	const loanArrData = [];
	// eslint-disable-next-line array-callback-return
	maxTenure.map((item, index) => {
		if (item.Tenure <= tenure) {
			let futureDate;
			let addDays = 'M';
			if (installmentType === 'Daily' || installmentType === 'ForthNight') {
				addDays = 'd';
			} else if (installmentType === 'Weekly') {
				addDays = 'w';
			}
			if (index === 0) {
				const currentDate = moment().format('YYYY/MM/DD');
				futureDate = moment(currentDate).add(1, addDays);
				if (installmentType === 'ForthNight') {
					futureDate = moment(currentDate).add(14, addDays);
				}
			} else {
				console.log('installmentType', installmentType);
				futureDate = moment(nextDate).add(1, addDays);
				if (installmentType === 'ForthNight') {
					futureDate = moment(nextDate).add(14, addDays);
				}
			}

			const overAllInterset =
				parseFloat(checkPrincipal) * tenure * (parseFloat(percentage) / 100);
			const loanPrincipalCheck = Math.round(checkPrincipal) / tenure;
			const loanInterestCheck = Math.round(overAllInterset) / tenure;
			remaining = principal - loanPrincipalCheck;

			const loanData = {
				index: item.Tenure + 1,
				loanPrinciple: loanPrincipalCheck,
				loanInterest: loanInterestCheck,
				loanEMI: loanPrincipalCheck + loanInterestCheck,
				loanRemaining: Math.round(remaining),
				loanDueDate: moment(futureDate).format('YYYY-MM-DD'),
				loanDueDateUI: `${moment(futureDate).format('DD')} ${moment(futureDate)
					.format('MMMM')
					.substring(0, 3)} ${moment(futureDate).format('MM')}`,
			};
			loanArrData.push(loanData);
			remaining = remaining.toFixed(2);
		}
	});
	return loanArrData;
};

export const computeCustomSchedule = (
	principal,
	percentage,
	tenure,
	payment,
	tenureMonth,
	maxTenure,
	installmentType,
) => {
	let productPrincipalAmount = parseInt(principal) / parseInt(tenure);
	const interestAmount = principal * (percentage / 100);
	let productInterestAmount = parseInt(interestAmount) / parseInt(tenure);
	let productEMIAmount = parseInt(productPrincipalAmount) + parseInt(productInterestAmount);
	productEMIAmount = productEMIAmount.toFixed(2);
	const loanArrData = [];
	let consolidateAmount = 0;
	let remainingAmount = principal;
	let totalCustomPriciple = 0;
	let totalCustomInterest = 0;
	let totalCustomEMI = 0;
	let nextDate;

	// eslint-disable-next-line array-callback-return
	maxTenure.map((item, index) => {

		productPrincipalAmount = Math.round(productPrincipalAmount);
		productInterestAmount = Math.round(productInterestAmount);
		productEMIAmount = parseInt(productPrincipalAmount) + parseInt(productInterestAmount);

		let principalAmt = productPrincipalAmount;
		if (index === tenure) {
			principalAmt = remainingAmount;
		}
		consolidateAmount += (principalAmt);

		remainingAmount = parseInt(principal) - parseInt(consolidateAmount);
		productEMIAmount = parseInt(principalAmt) + parseInt(productInterestAmount);

		totalCustomPriciple = parseInt(totalCustomPriciple) + parseInt(principalAmt);
		totalCustomInterest = parseInt(totalCustomInterest) + parseInt(Math.round(productInterestAmount));
		totalCustomEMI = parseInt(totalCustomEMI) + parseInt(Math.round(productEMIAmount));

		let futureDate;
		let addDays = 'M';
		if (installmentType === 'Daily' || installmentType === 'ForthNight') {
			addDays = 'd';
		} else if (installmentType === 'Weekly') {
			addDays = 'w';
		}
		if (index === 0) {
			const currentDate = moment().format('YYYY/MM/DD');
			futureDate = moment(currentDate).add(1, addDays).format('YYYY-MM-DD');
			if (installmentType === 'ForthNight') {
				futureDate = moment(currentDate).add(14, addDays);
			}
		} else {
			futureDate = moment(nextDate).add(1, addDays);
			if (installmentType === 'ForthNight') {
				futureDate = moment(nextDate).add(14, addDays);
			}
		}
		nextDate = futureDate;

		const loanData = {
			index: item.Tenure + 1,
			loanPrinciple: Math.round(principalAmt),
			loanInterest: Math.round(productInterestAmount),
			loanEMI: Math.round(productEMIAmount),
			loanRemaining: Math.round(remainingAmount),
			loanOpeningBal: Math.round(parseInt(remainingAmount) + principalAmt),
			loanDueDate: moment(futureDate).format('YYYY-MM-DD'),
			loanDueDateUI: `${moment(futureDate).format('DD')} ${moment(futureDate).format('MMMM').substring(0, 3)} ${moment(futureDate).format('MM')}`,
		};
		loanArrData.push(loanData);

		// if (item.Tenure <= tenure) {
		// 	let interest = Math.round(remaining) * (percentage / 100 / tenureMonth);
		// 	interest = interest.toFixed(2);
		// 	let principle = payment - interest;
		// 	principle = Math.round(principle);
		// 	interest = Math.round(interest);
		// 	let EMI = parseFloat(principle) + parseFloat(interest);
		// 	remaining -= principle;

		// 	let futureDate;
		// 	let addDays = 'M';
		// 	if (installmentType === 'Daily' || installmentType === 'ForthNight') {
		// 		addDays = 'd';
		// 	} else if (installmentType === 'Weekly') {
		// 		addDays = 'w';
		// 	}
		// 	if (index === 0) {
		// 		const currentDate = moment().format('YYYY/MM/DD');
		// 		futureDate = moment(currentDate).add(1, addDays).format('YYYY-MM-DD');
		// 		if (installmentType === 'ForthNight') {
		// 			futureDate = moment(currentDate).add(14, addDays);
		// 		}
		// 	} else {
		// 		console.log('installmentType', installmentType);
		// 		futureDate = moment(nextDate).add(1, addDays);
		// 		if (installmentType === 'ForthNight') {
		// 			futureDate = moment(nextDate).add(14, addDays);
		// 		}
		// 	}
		// 	// const futureMonthEnd = moment(futureDate).endOf('month');

		// 	// if (futureDate.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
		// 	//   futureDate = futureDate.add(1, 'd');
		// 	// }
		// 	nextDate = futureDate;
		// 	if (tenure === item.Tenure) {
		// 		principle += remaining;
		// 		EMI += remaining;
		// 		remaining = 0;
		// 	}

		// 	const loanData = {
		// 		index: item.Tenure + 1,
		// 		loanPrinciple: Math.round(principle),
		// 		loanInterest: Math.round(interest),
		// 		loanEMI: Math.round(EMI),
		// 		loanRemaining: Math.round(remaining),
		// 		loanDueDate: moment(futureDate).format('YYYY-MM-DD'),
		// 		loanDueDateUI: `${moment(futureDate).format('DD')} ${moment(futureDate).format('MMMM').substring(0, 3)} ${moment(futureDate).format('MM')}`,
		// 	};
		// 	loanArrData.push(loanData);
		// 	remaining = remaining.toFixed(2);
		// }
	});
	return loanArrData;
};
