/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import staffActionTypes from './staff.types';
import generalTypesAction from '../genera.types';
import { createStaff, getAllStaff, updateStaff, deleteStaff } from './staff.services';
import {
	getAllListSuccess,
	getAllListFailure,
	getAllListChanged,
	createStaffSuccess,
	createStaffFailure,
	staffCreateChanged,
	updateStaffSuccess,
	updateStaffFailure,
	staffUpdateChanged,
	deleteStaffSuccess,
	deleteStaffFailure,
	staffDeleteChanged,
} from './staff.actions';
import { encodeJson } from '../enode-decode';

export function* createNewStaff({
	payload: { values, companyID, userID, userType, branchCredentials },
}) {
	try {
		const jsonData = {
			UserName: values.userName,
			CreatedBy: userID,
			UserType: userType,
			UserPhone: values.userPhone,
			UserPassword: values.userPassword,
			UserAddress: values.userAddress,
			UserCity: values.userCity,
			UserState: values.userState,
			UserCountry: values.userCountry,
			UserPhoto: values.userPhoto,
			CompanyID: companyID,
			Latitude: values.latitude,
			Longitude: values.longitude,
			BranchID: branchCredentials.branchID,
			UserZipCode: values.userPincode,
			UserPostOffice: values.selectedPinCode,
			UserLoanList: values.userLoanList,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.STAFF);
		const responseData = yield call(createStaff, StaffData);
		if (responseData.data.statusCode === '100') {
			yield put(createStaffFailure(responseData.data));
		} else {
			yield put(createStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createStaffFailure(error));
	}
}

export function* updateStaffDetails({
	payload: { values, companyID, userType, branchCredentials },
}) {
	try {
		const jsonData = {
			UserID: values.userID,
			UserName: values.userName,
			UserType: userType,
			UserPhone: values.userPhone,
			UserPassword: values.userPassword,
			UserAddress: values.userAddress,
			UserCity: values.userCity,
			UserState: values.userState,
			UserCountry: values.userCountry,
			UserPhoto: values.userPhoto,
			CompanyID: companyID,
			Latitude: values.latitude,
			Longitude: values.longitude,
			BranchID: branchCredentials.branchID,
			UserZipCode: values.userPincode,
			UserPostOffice: values.selectedPinCode,
			FromWeb: '1',
			UserLoanList: values.userLoanList,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.STAFF);
		const responseData = yield call(updateStaff, StaffData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffFailure(responseData.data));
		} else {
			yield put(updateStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffFailure(error));
	}
}

export function* deleteStaffDetails({ payload: { userID, companyID } }) {
	try {
		const jsonData = {
			UserID: userID,
			CompanyID: companyID,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.STAFF);
		const responseData = yield call(deleteStaff, StaffData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteStaffFailure(responseData.data));
		} else {
			yield put(deleteStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteStaffFailure(error));
	}
}

export function* createListOfStaff({ payload: { limit, companyID } }) {
	try {
		const jsonData = {
			Limit: limit,
			CompanyID: companyID,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.STAFF);
		const responseData = yield call(getAllStaff, StaffData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* staffFilterList({ payload: { searchVal, companyID } }) {
	try {
		const jsonData = {
			SearchVal: searchVal,
			CompanyID: companyID,
		};
		const StaffData = encodeJson(jsonData, generalTypesAction.STAFF);
		const responseData = yield call(getAllStaff, StaffData);
		if (responseData.data.statusCodeCode === '100') {
			yield put(getAllListFailure(responseData.data));
		} else {
			yield put(getAllListSuccess(responseData.data.response));
		}
	} catch (error) {
		yield put(getAllListFailure(error));
	}
}

export function* createStaffResponseReset() {
	yield put(staffCreateChanged());
}

export function* updateStaffResponseReset() {
	yield put(staffUpdateChanged());
}

export function* deleteStaffResponseReset() {
	yield put(staffDeleteChanged());
}

export function* createListOfStaffReset() {
	yield put(getAllListChanged());
}

export function* staffCreateStart() {
	yield takeLatest(staffActionTypes.STAFF_CREATE_REPSONSE_START, createNewStaff);
}

export function* staffCreateResponseReset() {
	yield takeLatest(staffActionTypes.STAFF_CREATE_REPSONSE_RESET, createStaffResponseReset);
}

export function* staffUpdateStart() {
	yield takeLatest(staffActionTypes.STAFF_UPDATE_REPSONSE_START, updateStaffDetails);
}

export function* staffUpdateResponseReset() {
	yield takeLatest(staffActionTypes.STAFF_UPDATE_REPSONSE_RESET, updateStaffResponseReset);
}

export function* staffDeleteStart() {
	yield takeLatest(staffActionTypes.STAFF_DELETE_REPSONSE_START, deleteStaffDetails);
}

export function* staffDeleteResponseReset() {
	yield takeLatest(staffActionTypes.STAFF_DELETE_REPSONSE_RESET, deleteStaffResponseReset);
}

export function* getAllStaffList() {
	yield takeLatest(staffActionTypes.STAFF_LIST_START, createListOfStaff);
}

export function* getAllStaffListReset() {
	yield takeLatest(staffActionTypes.STAFF_LIST_RESET, createListOfStaffReset);
}

export function* getAllStaffFilterList() {
	yield takeLatest(staffActionTypes.STAFF_GET_FILTER_SEARCH_LIST, staffFilterList);
}

export function* staffSaga() {
	yield all([
		call(getAllStaffList),
		call(getAllStaffListReset),
		call(staffCreateStart),
		call(staffCreateResponseReset),
		call(staffUpdateStart),
		call(staffUpdateResponseReset),
		call(staffDeleteStart),
		call(staffDeleteResponseReset),
		call(getAllStaffFilterList),
	]);
}
