const dashboardActionTypes = {
	DASHBOARD_LOAD_REPSONSE_START: 'DASHBOARD_LOAD_REPSONSE_START',
	DASHBOARD_LOAD_REPSONSE_SUCCESS: 'DASHBOARD_LOAD_REPSONSE_SUCCESS',
	DASHBOARD_LOAD_REPSONSE_FAILURE: 'DASHBOARD_LOAD_REPSONSE_FAILURE',
	DASHBOARD_LOAD_REPSONSE_CHANGED: 'DASHBOARD_LOAD_REPSONSE_CHANGED',
	DASHBOARD_LOAD_REPSONSE_RESET: 'DASHBOARD_LOAD_REPSONSE_RESET',

	// COLLECTION SECTION
	DASHBOARD_COLLECTION_START: 'DASHBOARD_COLLECTION_START',
	DASHBOARD_COLLECTION_SUCCESS: 'DASHBOARD_COLLECTION_SUCCESS',
	DASHBOARD_COLLECTION_FAILURE: 'DASHBOARD_COLLECTION_FAILURE',
	DASHBOARD_COLLECTION_CHANGED: 'DASHBOARD_COLLECTION_CHANGED',
	DASHBOARD_COLLECTION_RESET: 'DASHBOARD_COLLECTION_RESET',
};
export default dashboardActionTypes;
