// import { useJwt, encodeToken } from 'react-jwt';
// const jwt = require('jsonwebtoken');
import JWT from 'expo-jwt';

// useJwt Token Encode
export const encodeJson = (encodeData, key) =>
	// jwt.sign(JSON.stringify(encodeData), key, { algorithm: 'HS512' });
	JWT.encode(encodeData, key, { algorithm: 'HS512' });
// encodeToken(encodeData, key, {
// 	algorithm: 'HS512',
// });

// useJwt Token Decode
export const decodeJson = (decodeData, key) =>
	JWT.encode(JSON.stringify(decodeData), key, { algorithm: 'HS512' });
// useJwt.decode(decodeData, key, {
// 	algorithm: 'HS512',
// });
