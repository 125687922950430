// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import JWT from 'expo-jwt';
import ReactS3 from 'react-s3';

// Decode
export const getEndodeJsonData = (jsonData, key) => {
	// console.log('jsonData', jsonData, 'key', key);
	const decodedData = JWT.decode(jsonData, key);
	return decodedData;
};

// S3 IMAGE UPLOAD
export const imageUploadToS3 = async (result, path) => {
	// Create a new Blob with the same content and the desired name
	const newFile = new Blob([result], { type: 'image/png' });
	newFile.name = `${Date.now()} -S3`.png;

	const config = {
		dirName: `${path}`,
		bucketName: 'paisanow-production',
		region: 'ap-south-1',
		accessKeyId: 'AKIAXLKVNHKWYHS5A25O',
		secretAccessKey: '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',
		successActionStatus: 201,
	};

	// eslint-disable-next-line consistent-return
	const imageUrl = ReactS3.uploadFile(newFile, config).then((response) => {
		if (response.location) {
			return response.location;
		}
	});
	return imageUrl;
};
